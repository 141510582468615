const emailRegExp =
	/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
const phoneRegExp = /[0-9\-\+]+$/i
const slimPostalCodeRegExp = /^[1-9][0-9]{3} ?((?!sa|sd|ss)[a-z]{2})?$/i
const postalCodeRegExp = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
const numericRegExp = /^\d+$/
const alphanumericRegExp = /^([a-zA-Z0-9 '.-]+)$/i
const alphabeticalRegExp = /^([a-zA-Z '.-]+)$/i

const patterns = {
	email: emailRegExp,
	phone: phoneRegExp,
	slim_postal_code: slimPostalCodeRegExp,
	postal_code: postalCodeRegExp,
	numeric: numericRegExp,
	alphanumeric: alphanumericRegExp,
	alphabetical: alphabeticalRegExp,
}

export const GetTypeOf = input =>
	Object.prototype.toString.call(input).split(/\W/)[2]

export const IsBoolean = value =>
	GetTypeOf(value) === 'Boolean' || [true, false, 1, 0].includes(value)
export const IsFunction = value => GetTypeOf(value) === 'Function'
export const IsObject = value => GetTypeOf(value) === 'Object'
export const IsArray = value => GetTypeOf(value) === 'Array'
export const IsNull = value => value === null
export const IsString = value => GetTypeOf(value) === 'String'
export const IsEmptyString = value =>
	GetTypeOf(value) === 'String' && value === ''
export const IsUndefined = value =>
	(value === undefined || typeof value === 'undefined') && value === void 0
export const IsSet = value => !IsUndefined(value) && !IsNull(value)
export const IsNumeric = value => numericRegExp.test(value)
export const IsAlphaNumeric = value => alphanumericRegExp.test(value)
export const IsAlphabetical = value => alphabeticalRegExp.test(value)
export const IsEmail = value => emailRegExp.test(value)
export const IsPhoneNumber = value => phoneRegExp.test(value)
export const IsSlimPostalCode = value => slimPostalCodeRegExp.test(value)
export const IsPostalCode = value => postalCodeRegExp.test(value)

export const GetTypeOfVideo = url => {
	if (url.includes('youtu')) {
		return 'youtube'
	} else if (url.includes('vimeo')) {
		return 'vimeo'
	}
	return null
}

export default GetTypeOf
