'use client'
import { LOCAL_STORAGE } from '@constants/local-storage.constants'

import { createRandomString } from './create-random-string.helpers'
import { getFromLocalStorage } from './local-storage.helpers'
import { setLocalStorage } from './local-storage.helpers'

export const getUserId = (): string => {
  const exists = getFromLocalStorage(LOCAL_STORAGE.USER_ID)
  if (exists) return exists

  const newId = createRandomString()
  setLocalStorage(LOCAL_STORAGE.USER_ID, newId)
  return newId
}
