'use client'
import { useMemo } from 'react'
import { RaIcon, RaPaper, RaTypography } from '@components/core/index.core'
import { translate } from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'
import clsx from 'clsx'

import { useScrollPosition } from '@utilities/hooks/useScrollPosition.hooks'

import styles from './ra-back-to-top.module.scss'

const SCROLL_THRESHOLD = 1500

export const RaBackToTop = ({ stickyFooterThreshold = false }) => {
  const { scrollY } = useScrollPosition()

  const label = translate('BACK_TO_TOP')
  const goToTop = () => {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const classes = useMemo(() => {
    return clsx(
      styles['ra-back-to-top'],
      scrollY > SCROLL_THRESHOLD && styles['ra-back-to-top--active'],
      stickyFooterThreshold && styles['ra-back-to-top--offset-bottom']
    )
  }, [scrollY])

  return (
    <button
      className={classes}
      onClick={goToTop}
      tabIndex={scrollY > SCROLL_THRESHOLD ? 0 : -1}>
      <RaPaper>
        <RaIcon
          icon={ICONS.CHEVRON_UP}
          useRootEl
        />
        <RaTypography
          size="s"
          align="center"
          component="span">
          {label}
        </RaTypography>
      </RaPaper>
    </button>
  )
}
