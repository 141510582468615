'use client'
import { RaBadge, RaButton, RaIcon } from '@core'

import { ICONS } from '@constants/icons.constants'

import { useSettings } from '@/contexts/settings.context'
import { useWishlist } from '@/contexts/wishlist.context'
import { stripFrontendUrl } from '@/utilities/helpers/strip-fe-url.helpers'

interface RaButtonWishlistProps {
  hasNavbar?: boolean
  isMobile?: boolean
}

export const RaButtonWishlist = ({
  isMobile,
  hasNavbar,
}: RaButtonWishlistProps) => {
  const { themeSettings } = useSettings()
  const { enableWishlist, wishlistItemsCount } = useWishlist()

  if (!enableWishlist) return null

  return (
    <div style={{ position: 'relative' }}>
      <RaButton
        link={{
          title: 'Wishlist',
          url: stripFrontendUrl(themeSettings?.wishlist.wishlist_page.url),
        }}
        variant="icon"
        icon={
          <RaIcon
            icon={isMobile ? ICONS.WISHLIST_CONTAINED : ICONS.WISHLIST}
            color={hasNavbar ? 'dark' : 'light'}
          />
        }
      />
      <RaBadge
        withBorder
        badge={wishlistItemsCount}
        smallFont
      />
    </div>
  )
}
