import { env } from 'next-runtime-env'
import { isServer } from './is-server'
import { LOCAL_STORAGE } from '@constants/local-storage.constants'

export const getBaseUrl = () => {
  if (isServer) {
    return env('WP_BASE_URL') || process.env.WP_BASE_URL
  } else {
    const ls = localStorage.getItem(LOCAL_STORAGE.WP_URL)
    if (!ls) return ''
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE.WP_URL))
  }
}
