'use client'
import { useMemo } from 'react'
import clsx from 'clsx'

import {
  RaButton,
  RaContainer,
  RaNavbarLogo,
} from '@components/core/index.core'
import { RaButtonTravelplan } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useUi } from '@contexts/ui.context'
import { ThemeSettings } from '@typings'

import { RaMobileMenu } from '../../features/RaMobileMenu/RaMobileMenu'

import styles from './ra-navbar-mobile.module.scss'
import { RaButtonWishlist } from '@/components/features/RaButtonWishlist/RaButtonWishlist'

interface IRaNavbarMobile extends ThemeSettings {
  isRoot: boolean
  showNavbar: boolean
  destination?: string
  isFamilySite?: boolean
}

export const RaNavbarMobile = ({
  blogname,
  blogdescription,
  site_label,
  menus,
  isRoot,
  showNavbar,
  destination,
  isFamilySite,
}: IRaNavbarMobile) => {
  const { showModal } = useUi()
  const handleClick = () => {
    showModal({
      content: <RaMobileMenu menu={menus.mobile} />,
      fullscreen: true,
      isMenu: true,
      bgColor: 'var(--color-primary)',
    })
  }

  const rootStyles = useMemo(() => {
    return clsx(
      styles['ra-navbar-mobile'],
      showNavbar && styles['ra-navbar-mobile--active']
    )
  }, [showNavbar])

  return (
    <RaContainer containerClasses={styles['ra-navbar-mobile__container']}>
      <div
        className={rootStyles}
        style={
          {
            '--navbar-text-color': showNavbar
              ? 'var(--color-primary)'
              : 'var(--color-light)',
          } as React.CSSProperties
        }>
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <RaButton
            variant="icon"
            icon={ICONS.HAMBURGER}
            ariaLabel="Menu"
            textColor={showNavbar ? 'dark' : 'light'}
            onClick={handleClick}
          />
          <RaNavbarLogo
            title={blogname}
            subtitle={blogdescription}
            logoIcon={site_label}
            isOpen={showNavbar}
            destination={destination}
            isFamilySite={isFamilySite}
          />
        </div>
        {!isRoot ? (
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            <RaButtonWishlist
              isMobile
              hasNavbar={showNavbar}
            />
            <RaButtonTravelplan
              hasNavbar={showNavbar}
              isMobile
            />
          </div>
        ) : (
          <div style={{ width: 28 }}></div>
        )}
      </div>
    </RaContainer>
  )
}
