import { getCurrency } from '@utilities/helpers/get-currency.helpers'
import { transformProductType } from '@utilities/helpers/transform-product-type.helpers'
import { GA4CartItemProps, Ga4RequestFormPaginationEvent } from '@typings'

import { ga4getDepartureDate } from './ga4-get-departure-date.helpers'
import { ga4GetFieldValue } from './ga4-get-field-value.helpers'
import { ga4GetTotalPrice } from './ga4-get-total-price.helpers'
import { ga4MakeEcommerceItem } from './ga4-make-ecommerce-item.helpers'

const REQ_FORM_FIELDS = {
  GENDER: 7,
  CONTACT_MOMENT: 13,
  ADULTS: 15,
  CHILDREN: 16,
  BABIES: 17,
  DURATION_FLEXIBILITY: 20,
  FLIGHT_BOOKED: 22,
  FLIGHT_PROPOSAL: 34,
  DEPARTURE: 18,
}

export const ga4MakeRequestFormDataLayer = (
  products: GA4CartItemProps[],
  fieldValues: object,
  page?: {
    number: number | string
    title: string
  }
) => {
  if (!fieldValues) return null

  const getValue = (id: number | string) => ga4GetFieldValue(id, fieldValues)

  const adults = getValue(REQ_FORM_FIELDS.ADULTS)
    ? parseInt(getValue(REQ_FORM_FIELDS.ADULTS))
    : 1
  const children = getValue(REQ_FORM_FIELDS.CHILDREN)
    ? parseInt(getValue(REQ_FORM_FIELDS.CHILDREN))
    : 0
  const babies = getValue(REQ_FORM_FIELDS.BABIES)
    ? parseInt(getValue(REQ_FORM_FIELDS.BABIES))
    : 0

  const numberOfPeople = adults + children + babies
  const partyComposition = !adults
    ? null
    : `${adults || 0}a, ${children || 0}c, ${babies || 0}b`
  const departure = getValue(REQ_FORM_FIELDS.DEPARTURE)
  const { fullDepartureDate, departureMonth, departureYear, daysToDeparture } =
    ga4getDepartureDate(departure)

  return {
    ecommerce: {
      value: ga4GetTotalPrice(products, adults),
      currency: getCurrency(),
      items: products?.map(p => ga4MakeEcommerceItem(p)),
    },
    tripType: transformProductType(products?.[0]?.type),
    country: products?.[0]?.country,
    region: products?.[0]?.region,
    tripName: products?.map(({ title }) => title).join(', ') || null,
    tripPrice: ga4GetTotalPrice(products, numberOfPeople),
    tripScheme: products?.map(({ itinerary }) => itinerary).join(', ') || null,
    tripDuration: products?.map(({ duration }) => duration).join(', ') || null,
    stepNumber: page?.number,
    stepName: page?.title || '',
    gender: getValue(REQ_FORM_FIELDS.GENDER),
    contactMoment: getValue(REQ_FORM_FIELDS.CONTACT_MOMENT),
    numberOfPeople,
    partyComposition,
    adults: adults,
    children: children,
    babies: babies,
    departureDate: fullDepartureDate,
    departureYear,
    departureMonth,
    daysToDeparture,
    tripDurationFlexibility: getValue(REQ_FORM_FIELDS.DURATION_FLEXIBILITY),
    flightBooked: getValue(REQ_FORM_FIELDS.FLIGHT_BOOKED),
    flightProposal: getValue(REQ_FORM_FIELDS.FLIGHT_PROPOSAL),
  } as Ga4RequestFormPaginationEvent
}
