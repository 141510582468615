import { LOCAL_STORAGE } from '@constants/local-storage.constants'

import { isServer } from './is-server'
import { getFromLocalStorage } from './local-storage.helpers'

export const translate = (string: string) => {
  let translationsDict = null
  if (isServer) translationsDict = global.translations
  if (!isServer)
    translationsDict = getFromLocalStorage(LOCAL_STORAGE.TRANSLATIONS)

  if (!translationsDict) return string
  const match = translationsDict?.find(({ key }) => key === string)
  if (match) return match.value
  return string
}
