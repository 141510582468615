export const SETTINGS = {
  DATE_FORMAT: 'YYYY-MM-DD',
  COOKIES_STANDARD:
    'We plaatsen functionele, analytische en marketingcookies en bieden je hiermee een optimale gebruikerservaring.',
  COOKIES_CUSTOM:
    'We plaatsen functionele en (geanonimiseerde) analytische cookies zodat de website goed blijft werken. We plaatsen geen marketingcookies.',
  DISABLE_CART_DOMAINS: [
    'default',
    'werken-bij',
    'family',
    'aktiv',
    'familienreisen',
  ],
}
