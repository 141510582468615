import { getCurrentEnv, isServer } from '@utilities/helpers/core.helpers'

export const ga4GetContentGroup = () => {
  if (isServer) return null
  const env = getCurrentEnv()
  if (!env) return null
  const url = window?.location?.href
  if (env === 'RIKSJA') {
    switch (true) {
      case url.includes('rondreis') || url.includes('rondreizen'):
        return 'Roundtrip'

      case url.includes('bouwsteen') || url.includes('bouwstenen'):
        return 'Buildingblocks'

      case url.includes('reisblog') || url.includes('blog'):
        return 'Travelblog'

      case url.includes('reisplan'):
        return 'My Travelplan'

      case url.includes('wishlist'):
        return 'Wishlist'

      case url.includes('familiereizen') || url.includes('met-kinderen'):
        return 'Family'

      case url.includes('kaart'):
        return 'Map'

      case url.includes('informatie'):
        return 'Information'

      case url.includes('specialist'):
        return 'About Us'

      case url.includes('confirmlead'):
        return 'Request Completed'

      case url.includes('confirmsale'):
        return 'Booking Completed'

      default:
        return 'Home'
    }
  }
  if (env === 'ERLEBE') {
    switch (true) {
      case url.includes('rundreis') || url.includes('rundreisen'):
        return 'Roundtrip'

      case url.includes('reisebausteine') || url.includes('baustein'):
        return 'Buildingblocks'

      case url.includes('blog'):
        return 'Travelblog'

      case url.includes('anfrageformular'):
        return 'My Travelplan'

      case url.includes('wishlist'):
        return 'Wishlist'

      case url.includes('familienreisen') || url.includes('mit-kindern'):
        return 'Family'

      case url.includes('infos'):
        return 'Information'

      case url.includes('ueber-uns'):
        return 'About Us'

      case url.includes('confirmlead'):
        return 'Request Completed'

      case url.includes('confirmsale'):
        return 'Booking Completed'

      default:
        return 'Home'
    }
  }
  if (env === 'RICKSHAW') {
    switch (true) {
      case url.includes('itineraries'):
        return 'Roundtrip'

      case url.includes('trips'):
        return 'Buildingblocks'

      case url.includes('blog'):
        return 'Travelblog'

      case url.includes('my-travelplan'):
        return 'My Travelplan'

      case url.includes('wishlist'):
        return 'Wishlist'

      case url.includes('family-holidays'):
        return 'Family'

      case url.includes('map'):
        return 'Map'

      case url.includes('information'):
        return 'Information'

      case url.includes('specialist'):
        return 'About Us'

      case url.includes('confirmlead'):
        return 'Request Completed'

      case url.includes('confirmsale'):
        return 'Booking Completed'

      default:
        return 'Home'
    }
  }
}
