export const ICONS = {
  GLOBE_ICON: 'GLOBE_ICON',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHEVRON_UP: 'CHEVRON_UP',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  CHEVRON_LEFT: 'CHEVRON_LEFT',
  SUITCASE_OUTLINED: 'SUITCASE_OUTLINED',
  SUITCASE_CONTAINED: 'SUITCASE_CONTAINED',
  CLOSE_ICON: 'CLOSE_ICON',
  SUCCESS: 'SUCCESS',
  SLIDE_LEFT: 'SLIDE_LEFT',
  ARROW_LEFT: 'ARROW_LEFT',
  SLIDE_RIGHT: 'SLIDE_RIGHT',
  ARROW_RIGHT: 'ARROW_RIGHT',
  LOCATION_MARKER: 'LOCATION_MARKER',
  CALENDAR: 'CALENDAR',
  CHECKMARK: 'CHECKMARK',
  NAV_UNDERLINE: 'NAV_UNDERLINE',
  HEADING_ILLUSTRATION_1: 'HEADING_ILLUSTRATION_1',
  HEADING_ILLUSTRATION_2: 'HEADING_ILLUSTRATION_2',
  HEADING_ILLUSTRATION_3: 'HEADING_ILLUSTRATION_3',
  MOBILE: 'MOBILE',
  LINK_ARROW: 'LINK_ARROW',
  HAMBURGER: 'HAMBURGER',
  USP_ICON: 'USP_ICON',
  BLOCK_LINK: 'BLOCK_LINK',
  PUZZLE: 'PUZZLE',
  PUZZLE_FAMILY: 'PUZZLE_FAMILY',
  PUZZLE_NUMBER_1: 'PUZZLE_NUMBER_1',
  PUZZLE_NUMBER_2: 'PUZZLE_NUMBER_2',
  PUZZLE_NUMBER_3: 'PUZZLE_NUMBER_3',
  PUZZLE_INDEX: 'PUZZLE_INDEX',
  CLOCK: 'CLOCK',
  MAP_ROUTE: 'MAP_ROUTE',
  CURRENCY_EUR: 'CURRENCY_EUR',
  CURRENCY_GBP: 'CURRENCY_GBP',
  ZOOM: 'ZOOM',
  PLUS: 'PLUS',
  PHONE: 'PHONE',
  MOBILE_SLIDE_NEXT: 'MOBILE_SLIDE_NEXT',
  MOBILE_SLIDE_PREV: 'MOBILE_SLIDE_PREV',
  MOBILE_DRAWING: 'MOBILE_DRAWING',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  PINTEREST: 'PINTEREST',
  YOUTUBE: 'YOUTUBE',
  TWITTER: 'TWITTER',
  X: 'X',
  EDIT: 'EDIT',
  EMAIL: 'EMAIL',
  PAPER_PLANE: 'PAPER_PLANE',
  LOGO_ERLEBE: 'LOGO_ERLEBE',
  ERLEBE_HEART: 'ERLEBE_HEART',
  MINUS: 'MINUS',
  WISHLIST: 'WISHLIST',
  WISHLIST_CONTAINED: 'WISHLIST_CONTAINED',
  WISHLIST_ACTIVE: 'WISHLIST_ACTIVE',
  WISHLIST_ACTIVE_ERLEBE: 'WISHLIST_ACTIVE_ERLEBE',
  WISHLIST_ACTIVE_FAMILY: 'WISHLIST_ACTIVE_FAMILY',
}
