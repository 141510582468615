import type { CartItemProps, Ga4CartEventProps } from '@typings'

import { getCurrency } from '../helpers/get-currency.helpers'
import { transformProductType } from '../helpers/transform-product-type.helpers'

import { ga4GetTotalPrice } from './ga4-get-total-price.helpers'
import { ga4MakeEcommerceItem } from './ga4-make-ecommerce-item.helpers'
import { ga4MakeGA4Item } from './ga4-make-ga4-item.helpers'

export const ga4MakeCartEventsPayload = (products: CartItemProps[]) => {
  if (!products?.length) return null
  const transformedProducts = products.map(p => ga4MakeGA4Item(p))
  const mainProduct = transformedProducts.find(({ parent }) => !parent)

  const ecommerce = {
    value: ga4GetTotalPrice(transformedProducts),
    currency: getCurrency(),
    items: transformedProducts?.map(p => ga4MakeEcommerceItem(p)),
  }

  const rest = {
    tripType: transformProductType(mainProduct?.type),
    country: mainProduct?.country,
    region: mainProduct?.region,
    tripName: transformedProducts?.map(({ title }) => title).join(', ') || null,
    tripPrice: ga4GetTotalPrice(transformedProducts),
    tripScheme:
      transformedProducts?.map(({ itinerary }) => itinerary).join(', ') || null,
    tripDuration:
      transformedProducts?.map(({ duration }) => duration).join(', ') || null,
  }

  return {
    ecommerce,
    rest,
  } as Ga4CartEventProps
}
