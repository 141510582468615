'use client'
import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import styles from './ra-navbar-interaction.module.scss'

const SCROLL_THRESHOLD = 150

export const RaNavbarInteraction = ({ children, isOpen, setShowNavbar }) => {
  const [isBelowThreshold, setIsBelowThreshold] = useState(false)
  const [isMouseover, setIsMouseover] = useState(false)

  const handleMouseOver = (value: boolean) => {
    if (window.innerWidth > 768) {
      setIsMouseover(value)
    }
  }

  const handleHorizontalScroll = () => {
    if (window.scrollY > SCROLL_THRESHOLD) {
      setIsBelowThreshold(true)
    } else {
      setIsBelowThreshold(false)
    }
  }

  const renderNavbar = useMemo(() => {
    return isMouseover || isOpen || isBelowThreshold
  }, [isOpen, isBelowThreshold, isMouseover])

  const interactionClasses = useMemo(() => {
    return clsx(
      styles['navbar-interaction'],
      renderNavbar && styles['navbar-interaction--scrolling']
    )
  }, [renderNavbar])

  useEffect(() => {
    handleHorizontalScroll()
    window.addEventListener('scroll', handleHorizontalScroll)
    return () => {
      window.removeEventListener('scroll', handleHorizontalScroll)
    }
  }, [])

  useEffect(() => {
    setShowNavbar(renderNavbar)
  }, [renderNavbar])

  return (
    <>
      <div
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
        className={interactionClasses}></div>
      <div
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
        style={
          {
            height: '100%',
            '--navbar-color-primary': renderNavbar
              ? 'var(--color-primary)'
              : 'var(--color-white)',
            '--navbar-color-text': renderNavbar
              ? 'var(--color-dark)'
              : 'var(--color-white)',
          } as React.CSSProperties
        }>
        {children}
      </div>
    </>
  )
}
