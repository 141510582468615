import dayjs from 'dayjs'

export const ga4getDepartureDate = (date: string) => {
  const fullDepartureDate = dayjs(date).format('DD-MM-YYYY')
  const departureDate = dayjs(date).date()
  const departureYear = dayjs(date).year()
  const departureMonth = dayjs(date).month() + 1
  const departure = dayjs(date)
  const today = dayjs(new Date())
  const daysToDeparture = departure.diff(today, 'day')
  return {
    fullDepartureDate: !date ? null : fullDepartureDate,
    departureDate: !date ? null : departureDate,
    departureMonth: !date ? null : departureMonth,
    departureYear: !date ? null : departureYear,
    daysToDeparture: !daysToDeparture ? null : daysToDeparture,
  }
}
