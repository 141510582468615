import type {
  AcfImageObject,
  AcfLinkItemProps,
  AdditionalServiceItemProps,
} from '@typings'

export interface CartItemKeyFact {
  remove_from_detail: boolean | '0' | '1'
  teaser_checkbox: boolean
  text: string
  title: string
}
export interface CartItemProps {
  type: 'reise-baustein' | 'rundreise' | 'variant' | 'additional_service'
  id: string | number
  cartId: string
  idType: 'id' | 'gid'
  gid: string
  title: string
  teaser?: string
  image: AcfImageObject
  link?: AcfLinkItemProps
  keyFacts?: CartItemKeyFact[]
  parent?: string
  slug?: string
  region?: string
  country: string
}

export interface CartContextProps {
  cartItems: null | CartItemProps[]
  enableCart: boolean
  cartItemsCount: number
  addToCart: (items: CartItemProps[], skipModal?: boolean) => void
  removeFromCart: (item: CartItemProps) => void
  clearCart: () => void
  moveIndex: (direction: 'up' | 'down', item: CartItemProps) => void
}

export const isTypeAdditionalService = (
  obj: any
): obj is AdditionalServiceItemProps => {
  return 'gid' in obj
}
