import type { GA4CartItemProps } from '@typings'

export const ga4GetTotalPrice = (
  products: GA4CartItemProps[],
  qty: number = 1
) => {
  if (!products?.length) return null
  const price = products.map(({ price }) =>
    typeof price === 'string' ? parseInt(price) : price
  )
  return price.reduce((prev, acc) => prev + acc, 0) * qty
}
