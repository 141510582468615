import styles from './torn-edge.module.scss'

export const RaTornEdge = ({
  color = 'var(--color-bg-light)',
}: {
  color?: string
}) => {
  return (
    <div
      data-component="torn-edge"
      className={styles['torn-edge']}
      style={
        {
          '--torn-edge-color': color,
        } as React.CSSProperties
      }></div>
  )
}
