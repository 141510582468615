'use client'

import { LOCAL_STORAGE } from '@constants/local-storage.constants'

import { getFromLocalStorage } from './local-storage.helpers'

export const getUserIp = (): string => {
  const exists = getFromLocalStorage(LOCAL_STORAGE.USER_IP_HASH)
  if (exists) return exists
  return null
}
