import { getCurrentEnv, unescapeHTML } from '@utilities/helpers/core.helpers'

export const extractPrice = (price: string) => {
  if (!price) return null
  let formattedPrice = unescapeHTML(price)
  const isRickshaw = getCurrentEnv() === 'RICKSHAW'
  if (isRickshaw) {
    formattedPrice = formattedPrice.replace(',', '')
  } else {
    formattedPrice = formattedPrice.replace(/&euro;/gi, '€').replace('.', '')
  }

  const exp = isRickshaw
    ? new RegExp('.*£ ?([0-9]*).*', 'g')
    : new RegExp('.*€ ?([0-9]*).*', 'g')
  const extractedPrice = exp.exec(formattedPrice)

  return extractedPrice ? parseInt(extractedPrice[1]) : 0
}
