import { memo } from 'react'
import Link from 'next/link'

import { getCurrentEnv } from '@utilities/helpers/get-current-env.helpers'

import { DefaultLogo } from './DefaultLogo'
import { ErlebeLogo } from './ErlebeLogo'

import styles from './ra-navbar-logo.module.scss'

interface RaNavbarLogoProps {
  title: string
  subtitle?: string
  logoIcon?: 'puzzle' | 'puzzle-family'
  destination?: string
  isOpen: boolean
  isFamilySite?: boolean
}

export const RaNavbarLogo = memo(
  ({
    title,
    subtitle,
    logoIcon,
    destination,
    isOpen,
    isFamilySite,
  }: RaNavbarLogoProps) => {
    const isErlebe = getCurrentEnv() === 'ERLEBE'

    return (
      <Link
        href={destination ? `/${destination}` : '/'}
        className={styles['logo']}>
        {isErlebe ? (
          <ErlebeLogo
            subtitle={subtitle}
            isOpen={isOpen}
            isFamilySite={isFamilySite}
          />
        ) : (
          <DefaultLogo
            title={title}
            subtitle={subtitle}
            isOpen={isOpen}
            logoIcon={logoIcon}
          />
        )}
      </Link>
    )
  }
)

RaNavbarLogo.displayName = 'RaNavbarLogo'
