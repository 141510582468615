export const gfParseFieldValuesType = (value, showLabel = false) => {
  if (!value) return null
  switch (typeof value) {
    case 'string':
    case 'number':
    case 'boolean':
      return value

    case 'object':
      return showLabel ? value.text || value.value : value.value

    default:
      return value
  }
}
