export const hashCode = value => {
  let hash = 0,
    i,
    chr
  if (value.length === 0) return hash
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const cleanupExpiredCachedEntries = (cache, expires_in, now) => {
  Object.keys(cache).forEach(key => {
    if (cache[key].timestamp + expires_in <= now) {
      // console.log('[Memoize] Expired!', key)
      delete cache[key]
    }
  })

  // console.log('[Memoize] Validated Cache', Object.keys(cache))
}

export const Memoize = <F extends (...arg: any) => any>(
  func: F,
  expires_in = 10 * 60
): ((...args: any) => ReturnType<F>) => {
  // expires_in is in seconds
  const cache = {}

  return (...args) => {
    const argsKey = hashCode(JSON.stringify(args))
    const now = Date.now()

    cleanupExpiredCachedEntries(cache, expires_in, now)

    if (argsKey in cache && cache[argsKey].timestamp + expires_in > now) {
      // Cached version still valid
      // console.log('[memoize] Cached version!', argsKey)
    } else {
      // Fresh version or expired
      // console.log('[memoize] Fresh version!', argsKey)
      cache[argsKey] = {
        value: func(...args),
        timestamp: Date.now(),
      }
    }

    return cache[argsKey].value
  }
}
