import { GA4CartItemProps, Ga4EcommerceItemProps } from '@typings'

import { transformProductType } from '../helpers/transform-product-type.helpers'

export const ga4MakeEcommerceItem = (
  product: GA4CartItemProps,
  numberOfPeople = 1,
  departure = null,
  party = null
) => {
  if (!product) return null
  const item_category = transformProductType(product.type)

  return {
    item_name: product.title,
    item_id: product.id || product.gid,
    price: product.price,
    quantity: numberOfPeople,
    item_category,
    item_category2: product.region,
    item_category3: departure,
    item_category4: party,
  } as Ga4EcommerceItemProps
}
