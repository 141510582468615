import type { CartItemProps, GA4CartItemProps } from '@typings'
import { extractPrice } from '@utilities/helpers/extract-price'
import { findKeyFactByTitle } from '@utilities/helpers/find-keyfact-by-title.helpers'
import { getCurrency } from '@utilities/helpers/get-currency.helpers'
import { unescapeHTML } from '@utilities/helpers/unescape-html.helpers'

export const ga4MakeGA4Item = (product: CartItemProps) => {
  const { keyFacts, region, country, type, cartId, gid, idType, id, parent } =
    product

  let itinerary = ''
  itinerary =
    findKeyFactByTitle(keyFacts, 'TRIP_SCHEDULE') ||
    findKeyFactByTitle(keyFacts, 'TRIP_DEPARTURE') // todo: its correct, but strange. Discuss translatable string with Riksja
  if (!itinerary) {
    const route = findKeyFactByTitle(keyFacts, 'TRIP_ROUTE')
    if (route) itinerary = route
  }
  const price = findKeyFactByTitle(keyFacts, 'TRIP_PRICE')
  const duration = findKeyFactByTitle(keyFacts, 'TRIP_DURATION')

  return {
    type,
    cartId,
    gid,
    idType,
    title: unescapeHTML(product.title),
    id,
    price: extractPrice(price) || null,
    currency: getCurrency(),
    country,
    region,
    itinerary: unescapeHTML(itinerary) || itinerary,
    duration: unescapeHTML(duration) || duration,
    parent,
  } as GA4CartItemProps
}
