'use client'
import { useEffect, useState } from 'react'

import { ThemeSettings } from '@typings'

import { RaButton, RaIcon, RaTypography } from '../index.core'

import { ICONS } from '@/constants/icons.constants'
import { KEYS } from '@/constants/keys.constants'
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from '@/utilities/helpers/local-storage.helpers'
import styles from './ra-notification-bar.module.scss'

export interface RaNotificationBar {
  notification: ThemeSettings['notification']
}

export const RaNotificationBar = ({ notification }: RaNotificationBar) => {
  const [showNotification, setShowNotification] = useState(false)

  const handleNotificationClose = () => {
    setShowNotification(false)
    //const expiry = Date.now() + 3600 * 24 * 30
    const expiry = Date.now() + 1000 * 60 * 60 * 24 * 60
    setLocalStorage(KEYS.NOTIFICATION_EXPIRY, expiry)
  }

  useEffect(() => {
    const notificationExpiry = getFromLocalStorage(KEYS.NOTIFICATION_EXPIRY)
    if (notificationExpiry) {
      if (notificationExpiry < Date.now()) {
        console.log('Expired...')
        setShowNotification(!notification.hide_notification_bar)
        removeFromLocalStorage(KEYS.NOTIFICATION_EXPIRY)
      }
    } else {
      setShowNotification(!notification?.hide_notification_bar)
    }
  }, [])

  if (!showNotification) return null

  return (
    <div
      className={styles['ra-notification-bar']}
      style={{
        background: notification.background_color,
        color: notification.text_color,
      }}>
      <RaTypography
        fontFamily="heading"
        style={{ color: 'inherit', textAlign: 'center' }}>
        {notification.message}
      </RaTypography>
      {notification.link && (
        <RaButton
          link={notification.link}
          variant="secondary-hero"
          small
        />
      )}
      <RaButton
        variant="icon"
        onClick={handleNotificationClose}
        icon={
          <RaIcon
            icon={ICONS.CLOSE_ICON}
            color="light"
          />
        }
        style={{
          position: 'absolute',
          right: 12,
        }}
      />
    </div>
  )
}
