'use client'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import type { WishlistContextProps, WishlistItemProps } from '@typings'
import { LOCAL_STORAGE } from '@constants/local-storage.constants'
import { SETTINGS } from '@constants/settings.constants'
import { useUi } from '@contexts/ui.context'
import { GA4Events } from '@utilities/ga4-helpers/GA4Events.helpers'
import { getFromLocalStorage } from '@utilities/helpers/local-storage.helpers'
import { removeFromLocalStorage } from '@utilities/helpers/local-storage.helpers'
import { setLocalStorage } from '@utilities/helpers/local-storage.helpers'

import { GA4_EVENTS } from '@/constants/ga4-events.constants'
import { translate } from '@/utilities/helpers/translate.helpers'

const _default = null

export const WishlistContext = createContext<WishlistContextProps>(null)
WishlistContext.displayName = 'WishlistContext'

export const WishlistContextProvider = ({
  GTM_ID,
  destination,
  wishlistSettings,
  children,
}) => {
  const ga4Events = new GA4Events({ mode: 'client', gtmId: GTM_ID })
  const [wishlistItems, setWishlistItems] = useState<
    null | WishlistItemProps[]
  >(_default)
  const [enableWishlist, setEnableWishlist] = useState(false)
  const ui = useUi()

  // Methods
  const addToWishlist = (newItems: WishlistItemProps[]) => {
    if (wishlistItems) {
      // Check if cart already contains this item
      const alreadyInCart: WishlistItemProps[] = []
      newItems.forEach(newItem => {
        const match = wishlistItems.find(
          ({ cartId }) =>
            cartId === newItem.cartId && newItem.type !== 'additional_service'
        )
        if (match) alreadyInCart.push(match)
      })

      if (alreadyInCart?.length) {
        ui.showToaster({
          severity: 'warning',
          title: translate('ITEM_ALREADY_IN_WISHLIST'),
        })
        return
      }
    }

    ga4Events.addToCart(newItems, GA4_EVENTS.ADD_TO_WISHLIST)
    ui.showToaster({
      severity: 'success',
      title: translate('ITEM_ADDED_TO_WISHLIST'),
    })

    if (!wishlistItems || wishlistItems.length === 0) {
      setWishlistItems(newItems)
      setLocalStorage(
        `${destination}_${LOCAL_STORAGE.WISHLIST_ITEMS}`,
        newItems
      )
    } else {
      setWishlistItems(() => [...wishlistItems, ...newItems])
      setLocalStorage(`${destination}_${LOCAL_STORAGE.WISHLIST_ITEMS}`, [
        ...wishlistItems,
        ...newItems,
      ])
    }
  }

  const removeFromWishlist = (itemToRemove: WishlistItemProps) => {
    if (!wishlistItems) {
      console.warn('cannot remove items from empty wishlist')
      return
    }

    const itemRemoved = wishlistItems
      .filter(({ cartId }) => cartId !== itemToRemove.cartId)
      .filter(
        ({ parent }) => parent?.toString() !== itemToRemove.cartId.toString()
      )

    if (itemRemoved.length) {
      ga4Events.removeFromCart(itemRemoved, GA4_EVENTS.REMOVE_FROM_WISHLIST)
    } else {
      ga4Events.removeFromCart([], GA4_EVENTS.REMOVE_FROM_WISHLIST)
    }

    setWishlistItems(itemRemoved)
    setLocalStorage(
      `${destination}_${LOCAL_STORAGE.WISHLIST_ITEMS}`,
      itemRemoved
    )
  }

  const clearWishlist = () => {
    setWishlistItems(_default)
    removeFromLocalStorage(`${destination}_${LOCAL_STORAGE.WISHLIST_ITEMS}`)
  }

  const wishlistItemsCount = useMemo(() => {
    if (!wishlistItems) return 0
    return wishlistItems.length
  }, [wishlistItems])

  useEffect(() => {
    if (!destination) setEnableWishlist(false)
    if (destination && !SETTINGS.DISABLE_CART_DOMAINS.includes(destination))
      setEnableWishlist(true)
    if (
      !wishlistSettings?.enable_wishlist ||
      !wishlistSettings?.wishlist_page
    ) {
      setEnableWishlist(false)
    }
    const fromPreviousSession = getFromLocalStorage(
      `${destination}_${LOCAL_STORAGE.WISHLIST_ITEMS}`
    )
    if (fromPreviousSession) setWishlistItems(fromPreviousSession)
  }, [
    destination,
    wishlistSettings?.enable_wishlist,
    wishlistSettings?.wishlist_page,
  ])

  return (
    <WishlistContext.Provider
      value={{
        wishlistItems,
        enableWishlist,
        wishlistItemsCount,
        addToWishlist,
        removeFromWishlist,
        clearWishlist,
      }}>
      {children}
    </WishlistContext.Provider>
  )
}

export const useWishlist = () => {
  return useContext(WishlistContext)
}
