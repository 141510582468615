import { RaIcon, RaTypography } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import clsx from 'clsx'
import styles from './ra-navbar-logo.module.scss'

interface DefaultLogoProps {
  logoIcon: 'puzzle' | 'puzzle-family'
  isOpen: boolean
  title: string
  subtitle?: string
}

export const DefaultLogo = ({
  logoIcon,
  isOpen,
  title,
  subtitle,
}: DefaultLogoProps) => {
  const icon = logoIcon?.replaceAll('-', '_')?.toUpperCase()
  const isFamilySite = logoIcon === 'puzzle-family'

  return (
    <div className={styles['logo__titles']}>
      <div className={styles['logo__default-wrapper']}>
        <RaIcon
          icon={ICONS[icon]}
          color={isOpen ? 'primary' : 'light'}
        />
        <RaTypography
          component="span"
          fontFamily="heading"
          size={22}
          color={isOpen ? 'primary' : 'light'}>
          {title}
        </RaTypography>
      </div>
      {subtitle && (
        <RaTypography
          customClasses={clsx(
            styles['logo__titles__default-subtitle'],
            isFamilySite && styles['logo__titles__default-subtitle--family']
          )}
          component="span"
          fontFamily="heading"
          size="s"
          color={isOpen ? 'primary' : 'light'}>
          {subtitle}
        </RaTypography>
      )}
    </div>
  )
}
