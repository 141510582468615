import { useMemo } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import styles from './ra-paper.module.scss'

type HoverAnimation = 'shadow' | 'translateY' | 'image-zoom'
interface IRaPaper {
  children: React.ReactNode
  color?: string
  tornEdge?: boolean
  elevation?: 'super-light' | 'light' | 'heavy'
  noPadding?: boolean
  padding?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  paddingMobile?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  link?: {
    href: string
    title: string
  }
  hoverAnimations?: HoverAnimation[]
  zIndex?: number
  width?: '100%' | 'fit-content'
}

export const RaPaper = ({
  children,
  color = 'var(--color-white)',
  tornEdge = false,
  elevation = 'light',
  noPadding = false,
  padding = 2,
  paddingMobile = 2,
  link,
  hoverAnimations = null,
  zIndex = 5,
  width = '100%',
}: IRaPaper) => {
  const paperClasses = useMemo(() => {
    return clsx(
      styles['ra-paper'],
      tornEdge && styles['ra-paper--torn-edge'],
      noPadding && styles['ra-paper--no-padding'],
      elevation && styles[`ra-paper--elevation-${elevation}`],
      link?.href && styles['ra-paper--link'],
      hoverAnimations?.includes('shadow') && styles['ra-paper--animate-shadow'],
      hoverAnimations?.includes('translateY') &&
        styles['ra-paper--animate-translateY'],
      hoverAnimations?.includes('image-zoom') &&
        styles['ra-paper--animate-image-zoom']
    )
  }, [tornEdge, noPadding, elevation, link])

  if (link) {
    return (
      <Link
        data-component="paper"
        href={link.href}
        title={link.title}
        className={paperClasses}
        prefetch
        style={
          {
            '--ra-paper-color': color,
            '--ra-paper-spacing': `var(--ra-spacing-${padding})`,
            '--ra-paper-spacing-mobile': `var(--ra-spacing-${paddingMobile})`,
            zIndex,
            width,
          } as React.CSSProperties
        }>
        {children}
      </Link>
    )
  }

  return (
    <div
      data-component="paper"
      className={paperClasses}
      style={
        {
          '--ra-paper-color': color,
          '--ra-paper-spacing': `var(--ra-spacing-${padding})`,
          '--ra-paper-spacing-mobile': `var(--ra-spacing-${paddingMobile})`,
          zIndex,
        } as React.CSSProperties
      }>
      {children}
    </div>
  )
}
