import { getBaseUrl } from './get-base-url.helpers'
import { isServer } from './is-server'

export const getCurrentEnv = (): 'RIKSJA' | 'ERLEBE' | 'RICKSHAW' => {
  let base = null
  if (isServer) {
    base = getBaseUrl()
  } else {
    base = window?.location?.origin
  }

  switch (true) {
    case base?.includes('riksja'):
      return 'RIKSJA'

    case base?.includes('erlebe'):
      return 'ERLEBE'

    case base?.includes('rickshaw'):
      return 'RICKSHAW'

    default:
      return 'RIKSJA'
  }
}
