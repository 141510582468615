export const KEYS = {
  CONTENT: 'content',
  OPEN: 'open',
  PAGES: 'pages',
  PAGE: 'page',
  THEMES: 'themes',
  THEME: 'theme',
  TITLE: 'title',
  SUPPORT_EMAIL_ADDRESS: 'info@riksjatravel.nl',
  SUPPORT_PHONENUMBER: '',
  NOTIFICATION_EXPIRY: 'hide_notification_expiry',
}
