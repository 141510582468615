export const transformProductType = (
  type: 'rundreise' | 'reise-baustein' | 'variant' | 'additional_service'
) => {
  if (!type) return null
  switch (type) {
    case 'rundreise':
      return 'Roundtip'

    case 'reise-baustein':
      return 'Buildingblock'

    case 'variant':
      return 'Trip Variant'

    case 'additional_service':
      return 'Additional Service'
  }
}
