'use client'
import { RaIcon, RaTypography } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'

import styles from './ra-usp.module.scss'

interface RaUSPProps {
  text: string
}

export const RaUSP = ({ text }: RaUSPProps) => {
  return (
    <div className={styles['ra-usp']}>
      <RaIcon
        color="primary"
        icon={ICONS.USP_ICON}
      />
      <RaTypography component="span">{text}</RaTypography>
    </div>
  )
}
