import type { CartItemKeyFact } from '@typings'
import { translate } from '@utilities/helpers/core.helpers'

export const findKeyFactByTitle = (
  keyFacts: CartItemKeyFact[],
  searchValue: string
) => {
  if (!keyFacts?.length) return ''
  const fact = keyFacts?.find(
    ({ title }) => title === searchValue || title === translate(searchValue)
  )

  if (fact) return fact.text
  return null
}
