'use client'

import { createContext, useContext, useState } from 'react'

interface UiContextToaster {
  isOpen: boolean
  severity: string
  title: string
  content?: string
}

interface UiContextModal {
  isOpen: boolean
  title?: string
  content: string | React.ReactNode
  fullscreen?: boolean
  bgColor?: string
  maxWidth?: number
  isTravelplan?: boolean
  isMenu?: boolean
  customCloseButton?: string
}

interface UiContextToasterArgs {
  severity?: string
  title: string
  content?: string
}

interface UiContextModalArgs {
  title?: string
  content: string | React.ReactNode
  fullscreen?: boolean
  bgColor?: string
  maxWidth?: number
  isTravelplan?: boolean
  isMenu?: boolean
  customCloseButton?: string
}

interface UiContextLightbox {
  isOpen: boolean
  content: React.ReactNode
}

interface UiContextProps {
  toaster: UiContextToaster
  modal: UiContextModal
  lightbox: UiContextLightbox
  showToaster: (props: UiContextToasterArgs) => void
  showModal: (props: UiContextModalArgs) => void
  showLightbox: (content: React.ReactNode) => void
  closeToaster: () => void
  closeModal: () => void
  closeLightbox: () => void
}

const _defaultToaster = {
  isOpen: false,
  severity: '',
  title: '',
  content: '',
}

const _defaultModal = {
  isOpen: false,
  title: '',
  content: '',
  fullscreen: false,
  bgColor: null,
  maxWidth: null,
  isTravelplan: false,
  isMenu: false,
  customCloseButton: null,
}

const _defaultLightbox = {
  isOpen: false,
  content: null,
}

export const UiContext = createContext<UiContextProps | null>(null)
UiContext.displayName = 'UiContext'

export const UiContextProvider = ({ children }) => {
  const [toaster, setToaster] = useState(_defaultToaster)
  const [modal, setModal] = useState(_defaultModal)
  const [lightbox, setLightbox] = useState(_defaultLightbox)

  // Methods
  const showToaster = ({ severity = 'info', title, content }) => {
    setToaster({
      isOpen: true,
      severity,
      title,
      content,
    })

    setTimeout(() => {
      closeToaster()
    }, 3000)
  }

  const closeToaster = () => {
    setToaster({ ...toaster, isOpen: false })
    setTimeout(() => {
      setToaster(_defaultToaster)
    }, 1500)
  }

  const showModal = ({
    title,
    content,
    fullscreen = false,
    maxWidth,
    bgColor = null,
    isTravelplan = false,
    isMenu = false,
    customCloseButton = null,
  }) => {
    setModal({
      isOpen: true,
      title,
      content,
      fullscreen,
      maxWidth,
      bgColor,
      isTravelplan,
      isMenu,
      customCloseButton,
    })
  }

  const closeModal = () => {
    setModal({
      ...modal,
      isOpen: false,
    })
    setTimeout(() => {
      setModal(_defaultModal)
    }, 250)
  }

  const showLightbox = (content: React.ReactNode) => {
    setLightbox({
      isOpen: true,
      content,
    })
  }

  const closeLightbox = () => {
    setLightbox({ ...lightbox, isOpen: false })
    setTimeout(() => {
      setLightbox(_defaultLightbox)
    }, 250)
  }

  return (
    <UiContext.Provider
      value={{
        toaster,
        modal,
        lightbox,
        closeToaster,
        showToaster,
        showModal,
        closeModal,
        showLightbox,
        closeLightbox,
      }}>
      {children}
    </UiContext.Provider>
  )
}

export const useUi = () => {
  return useContext(UiContext)
}
