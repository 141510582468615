'use client'
import { useMemo } from 'react'
import { stripFrontendUrl } from '@core-helpers'
import Link from 'next/link'

import type { ThemeRegionParent } from '@typings'
import { RaContainer, RaIcon } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useSettings } from '@contexts/settings.context'
import { capitalize, Loop } from '@utilities/helpers/core.helpers'

import styles from './ra-breadrcrumbs.module.scss'

export interface RaBreadCrumbsProps {
  parentCategory?: string
}

export const RaBreadcrumbs = ({ parentCategory }: RaBreadCrumbsProps) => {
  const { params, themeSettings, destination, yoast_breadrcrumbs } =
    useSettings()

  const breadcrumbsList = useMemo(() => {
    // Not a product detail page
    if (!parentCategory) {
      if (!params) return null
      const crumbs = []

      if (yoast_breadrcrumbs) {
        yoast_breadrcrumbs.forEach((item, i) => {
          crumbs.push({
            index: i,
            raw: item?.link?.url,
            label:
              i === 0
                ? capitalize(destination.replace(/-/gi, ' '))
                : item?.link?.title,
            path: item?.link?.url,
            disabled: item.disabled,
          })
        })
      } else {
        params.forEach((param: string, i: number) => {
          const itemPath = crumbs?.map(({ raw }) => raw).join('/') + `/${param}`
          crumbs.push({
            index: i,
            raw: param,
            label: capitalize(param.replace(/-/gi, ' ')),
            path: crumbs.length > 0 ? `/${itemPath}` : `/${param}`,
            disabled: false,
          })
        })
      }
      return crumbs.filter(item => item.label !== 'Home')
    } else {
      // Product detail page
      const crumbs = []
      if (!yoast_breadrcrumbs) return null
      yoast_breadrcrumbs.forEach((item, i) => {
        crumbs.push({
          index: i,
          raw: item?.link?.url,
          label:
            i === 0
              ? capitalize(destination.replace(/-/gi, ' '))
              : item?.link?.title,
          path: item?.link?.url || '/',
          disabled: item.disabled,
        })
      })

      // const regionParents = themeSettings?.region_parents
      // if (regionParents) {
      //   const parentSlug: ThemeRegionParent = regionParents.find(
      //     item => item[item.type.replaceAll('-', '_')] === parentCategory
      //   )
      //   if (parentSlug) {
      //     const root = yoast_breadrcrumbs[0].link.url
      //     const parentPath = stripFrontendUrl(parentSlug.parent_page?.link)

      //     const parent = {
      //       raw: `${root}${parentSlug.parent_page?.post_name}`,
      //       path: parentPath,
      //       label: parentSlug.parent_page?.post_title,
      //       disabled: false,
      //     }
      //     crumbs.splice(crumbs.length - 1, 0, parent)
      //   }
      // }

      return crumbs
    }
  }, [params])

  const renderBreadCrumbs = useMemo(() => {
    return Loop(breadcrumbsList, (item, i: number) => {
      if (i === breadcrumbsList.length - 1 || item.disabled) {
        return <li key={i}>{item.label}</li>
      } else {
        return (
          <li key={i}>
            <Link href={item.path}>{item.label}</Link>
            <RaIcon
              icon={ICONS.CHEVRON_RIGHT}
              color="dark"
            />
          </li>
        )
      }
    })
  }, [])

  return (
    <RaContainer>
      <ul className={styles['ra-breadcrumbs']}>
        <li>
          <Link href="/">Home</Link>
          <RaIcon
            icon={ICONS.CHEVRON_RIGHT}
            color="dark"
          />
        </li>
        {renderBreadCrumbs}
      </ul>
    </RaContainer>
  )
}
